var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('loading-overlay',{attrs:{"active":_vm.loading,"is-full-page":true,"loader":"bars"}}),_c('CTabs',{attrs:{"variant":"tabs","active-tab":0}},[_c('CTab',{attrs:{"title":_vm.$t('label.YardCertification')}},[_c('CCard',{attrs:{"bodyWrapper":""}},[(!_vm.Collapse)?_c('CRow',[_c('CCol',{staticClass:"text-right",attrs:{"sm":"12"}},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                content: _vm.$t('label.YardCertification'),
                placement: 'top-end'
              }),expression:"{\n                content: $t('label.YardCertification'),\n                placement: 'top-end'\n              }"}],attrs:{"square":"","color":"add"},on:{"click":function($event){_vm.Collapse=true}}},[_c('CIcon',{attrs:{"name":"cil-playlist-add"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t('label.nueva')))])],1)],1),_c('CCol',{attrs:{"sm":"12"}},[_c('dataTableExtended',{staticClass:"align-center-row-datatable",attrs:{"size":"lg","fields":_vm.fields,"items":_vm.computedListDraft,"items-per-page":5,"column-filter":"","pagination":"","table-filter":_vm.tableText.tableFilterText,"items-per-page-select":_vm.tableText.itemsPerPageText,"noItemsView":_vm.tableText.noItemsViewText,"sorter":""},scopedSlots:_vm._u([{key:"Status",fn:function({item}){return [_c('td',{staticClass:"text-center"},[_c('CBadge',{attrs:{"color":_vm.getBadge(item.Status)}},[_vm._v(" "+_vm._s(_vm.$t('label.'+item.Status))+" ")])],1)]}},{key:"options",fn:function({ item }){return [_c('td',{staticClass:"center-cell"},[_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                            content: _vm.$t('label.edit')+' '+_vm.$t('label.YardCertification'),
                            placement: 'top-end'
                        }),expression:"{\n                            content: $t('label.edit')+' '+$t('label.YardCertification'),\n                            placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"square":"","size":"sm","color":"edit"},on:{"click":function($event){return _vm.toggleEdit(item)}}},[_c('CIcon',{attrs:{"name":"pencil"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                        content: _vm.$t('label.reverse'),
                        placement: 'top-end'
                      }),expression:"{\n                        content: $t('label.reverse'),\n                        placement: 'top-end'\n                      }"}],staticClass:"mr-1",attrs:{"square":"","color":"revert","size":"sm"},on:{"click":function($event){return _vm.Reverse(item)}}},[_c('CIcon',{attrs:{"name":"cil-reload"}})],1),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({
                          content: _vm.$t('label.download')+' PDF',
                          placement: 'top-end'
                        }),expression:"{\n                          content: $t('label.download')+' PDF',\n                          placement: 'top-end'\n                        }"}],staticClass:"mr-1",attrs:{"square":"","color":"danger","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.printPdf(item)}}},[_c('CIcon',{attrs:{"name":"cil-file-pdf"}})],1)],1)]}}],null,false,2461476457)})],1)],1):_vm._e(),_c('CCol',{attrs:{"sm":"12","lg":"12"}},[_c('CCollapse',{attrs:{"show":_vm.Collapse}},[_c('CollapseYardCertification',{attrs:{"Collapse":_vm.Collapse,"isEdit":_vm.isEdit,"item":_vm.item},on:{"close-Collapse":_vm.Close}})],1)],1)],1)],1)],1),_c('ModalYardCertificationReverse',{attrs:{"modal":_vm.ModalReverse},on:{"update:modal":function($event){_vm.ModalReverse=$event},"close-Modal":_vm.Close}}),_c('ModalYardCertificationPdf',{attrs:{"modal":_vm.ModalPdf},on:{"update:modal":function($event){_vm.ModalPdf=$event},"close-Modal":_vm.Close}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }